import React from "react";
import Typography from "@mui/material/Typography";

function Step1Header() {
  return (
    <>
      {" "}
      <Typography paragraph={true}>
        Note that any quotes or information provided are subject to Local
        Surveyors Direct{" "}
        <a
          href="https://www.localsurveyorsdirect.co.uk/terms-and-conditions-clients-agreement"
          target="_blank"
          rel="noreferrer"
        >
          Terms and Conditions
        </a>
        . Local Surveyors Direct will NOT use your contact details again, other
        than a brief feedback email to check that you were happy with the
        service provided. Please read{" "}
        <a
          href="https://www.localsurveyorsdirect.co.uk/customer-privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          LSD&apos;s Privacy Policy
        </a>{" "}
        for more information.
      </Typography>
      <Typography paragraph={true}>
        Contact details of local Asbestos surveyors will appear in a few seconds
        from our partners Local Surveyors Direct. You will be provided with a
        list of local Asbestos surveyors who can help you with your Asbestos
        issue together with their credentials, experience and contact details.
        If you wish, you can contact any of these surveyors directly, or they
        may contact you for more information so you can discuss your issue and
        they can give an initial quote.
      </Typography>
      <Typography paragraph={true}>
        To calculate the price for your survey, your property details are only
        provided to relevant surveyors who cover your area, along with your
        email and phone number. If you do not want to give your phone number
        please put 0000 in this field.
      </Typography>
      <Typography paragraph={true}>
        Local Surveyors Direct will NOT use your contact details again, other
        than a brief feedback email to check that you were happy with the
        service provided. Please read{" "}
        <a
          href="https://www.localsurveyorsdirect.co.uk/customer-privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          LSD&apos;s Privacy Policy
        </a>{" "}
        for more information.
      </Typography>
      <Typography paragraph={true}>
        The HomeOwners Alliance will NOT use your contact details unless you
        have opted in to receive our free newsletter. Please read{" "}
        <a
          href="https://hoa.org.uk/our-site/home-owners-alliance-privacy-policy/"
          target="_blank"
          rel="noreferrer"
        >
          HOA&apos;s Privacy Policy
        </a>{" "}
        for more information.
      </Typography>
    </>
  );
}

export default Step1Header;
